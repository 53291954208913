import { NAMESPACE } from '@models/enums';
import Styled from './TooltipContent.styled';
import { useTranslation } from 'next-i18next';

const TooltipContent = () => {
  const { t } = useTranslation(NAMESPACE.PROMOTION);
  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper>
        <Styled.Header data-testid="cashback-incentive-tooltip-rebate-title">{t('CASHBACK_INCENTIVE_TOOLTIP_REBATE_TITLE')}</Styled.Header>
        <Styled.Content data-testid="cashback-incentive-tooltip-rebate-content">
          {t('CASHBACK_INCENTIVE_TOOLTIP_REBATE_CONTENT')}
        </Styled.Content>
      </Styled.ContentWrapper>
      <Styled.ContentWrapper>
        <Styled.Header data-testid="cashback-incentive-tooltip-fees-title">{t('CASHBACK_INCENTIVE_TOOLTIP_FEES_TITLE')}</Styled.Header>
        <Styled.Content data-testid="cashback-incentive-tooltip-fees-content">
          {t('CASHBACK_INCENTIVE_TOOLTIP_FEES_CONTENT')}
        </Styled.Content>
      </Styled.ContentWrapper>
      <Styled.ContentWrapper>
        <Styled.Header data-testid="cashback-incentive-tooltip-service-title">
          {t('CASHBACK_INCENTIVE_TOOLTIP_SERVICE_TITLE')}
        </Styled.Header>
        <Styled.Content data-testid="cashback-incentive-tooltip-service-content">
          {t('CASHBACK_INCENTIVE_TOOLTIP_SERVICE_CONTENT')}
        </Styled.Content>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

export default TooltipContent;
