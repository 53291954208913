import { Button } from '@pinecorpca/spruce';
import styled from 'styled-components';

const StyledCashbackIncentive = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `,
  Card: styled.dl`
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    background-color: ${({ theme }) => theme.background.primary};
    padding: 32px;
    margin: 0;
  `,
  CashBackValue: styled.dd`
    font-size: 36px;
    line-height: 41px;
    font-family: var(--font-tiempos);
    font-weight: 400;
    color: ${({ theme }) => theme.text.primary};
    margin: 0;
  `,
  CashBackLabel: styled.dt`
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-tiempos);
    font-weight: 400;
    color: ${({ theme }) => theme.text.primary};
  `,
  SubmitButton: styled(Button)`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      width: auto;
    }
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      justify-content: flex-end;
    }
  `,
};

export default StyledCashbackIncentive;
