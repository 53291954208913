import { MAX_PERCENT_PURCHASE_PRICE } from '@components/DownPayment';
import { DealPurpose, PropertyOccupationType, Province, ShortAppCompleteDto } from '@pinecorpca/evergreen';
import { LeadSource } from '@models/enums';
type ReferralParams = {
  body: ShortAppCompleteDto;
  partner: LeadSource | null;
  enableReferral: boolean;
};

export const isWealthsimpleReferral = ({ body, partner, enableReferral }: ReferralParams) => {
  if (!enableReferral || partner !== LeadSource.Wealthsimple) return false;

  const isBCResidentialProperty =
    body?.address?.province === Province.BritishColumbia &&
    [PropertyOccupationType.OwnerOccupied, PropertyOccupationType.SecondHome].includes(
      body?.propertyOccupationType as PropertyOccupationType
    );

  if (isBCResidentialProperty && body?.dealPurpose !== DealPurpose.PreApproval) {
    return false;
  }

  const purchasePrice = body?.purchasePrice ?? 0;
  const isNonRentalProperty = body?.propertyOccupationType !== PropertyOccupationType.Rental;
  const isBelowMaxPrice = purchasePrice < MAX_PERCENT_PURCHASE_PRICE;
  const isNotRefinance = body?.dealPurpose !== DealPurpose.Refinance;

  if (isNonRentalProperty && isBelowMaxPrice && isNotRefinance) {
    return false;
  }

  return true;
};
