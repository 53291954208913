import { useShortAppContext } from 'contexts';
import Styled from './CashbackIncentive.styled';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';
import { calculateCashbackIncentive, formatCurrency } from 'utils';
import { ShortAppBody } from 'contexts';
import { useAmplitude } from 'contexts/amplitude';

interface CashbackIncentiveProps {
  onSubmit: (body: ShortAppBody) => void;
}

const CashbackIncentive = ({ onSubmit }: CashbackIncentiveProps) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const { body } = useShortAppContext();
  const { trackEvent } = useAmplitude();

  const cashbackIncentive = useMemo(() => calculateCashbackIncentive(body?.purchasePrice ?? 0), [body?.purchasePrice]);

  const handleOnSubmit = useCallback(
    (type: 'interested' | 'not_interested') => {
      const isInterested = type === 'interested';

      trackEvent('Cashback Incentive Selected', {
        section: 'cashback-incentive',
        step: 'cashback-incentive-selected',
        interested: isInterested,
      });

      onSubmit({
        cashbackIncentive: isInterested,
      });
    },
    [onSubmit, trackEvent]
  );

  return (
    <Styled.Wrapper>
      <Styled.Card>
        <Styled.CashBackValue data-testid="cashback-incentive-value">
          {formatCurrency(cashbackIncentive || 0, { maximumFractionDigits: 0 }) || 0}
        </Styled.CashBackValue>
        <Styled.CashBackLabel data-testid="cashback-incentive-label">{t('FLOW_CASHBACK_INCENTIVE_EXPECTED')}</Styled.CashBackLabel>
      </Styled.Card>
      <Styled.ButtonWrapper>
        <Styled.SubmitButton
          data-testid="cashback-incentive-not-interested"
          color="secondary"
          size="medium"
          onClick={() => handleOnSubmit('not_interested')}
        >
          {t('NOT_INTERESTED', { ns: NAMESPACE.COMMON })}
        </Styled.SubmitButton>
        <Styled.SubmitButton data-testid="cashback-incentive-interested" size="medium" onClick={() => handleOnSubmit('interested')}>
          {t('INTERESTED', { ns: NAMESPACE.COMMON })}
        </Styled.SubmitButton>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

export default CashbackIncentive;
