import Question from '@components/Question/Question';
import { ReducerActionType, useStepIndicator } from '@components/StepIndicator';
import { API_STATUS } from '@models/enums/apiStatus.enum';
import { FlowNode } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { OptionStruct } from '@models/option.model';
import { ShortAppCompleteDto } from '@pinecorpca/evergreen';
import { useShortAppContext } from 'contexts';
import { Chain } from 'hooks/models/hooks.model';
import useFlow from 'hooks/useFlow';
import { useCallback, useEffect } from 'react';
import useShortAppFlow from './configs/flow.config';
import { useConstructSteps } from './hooks/useConstructSteps';
import { useAmplitude } from 'contexts/amplitude';
import startCase from 'lodash/startCase';

interface ShortAppFlowProps {
  // onSubmit is used to override the default onSubmit behavior (default: navigating to register page)
  // this component is used for both authenticated and unauthenticated new application flows
  onSubmit?: (request: ShortAppCompleteDto) => Promise<void>;
}

const ShortAppFlow = ({ onSubmit }: ShortAppFlowProps): JSX.Element => {
  const { body, updateBody, replaceBody } = useShortAppContext();
  const shortAppForm = useShortAppFlow();
  const [flow, updateFlow, { currentQuestion }] = useFlow(shortAppForm, undefined);
  const steps = useConstructSteps(flow.chain, flow.currActiveQIdx);
  const { dispatch } = useStepIndicator();
  const { trackEvent } = useAmplitude('Short App Started', {
    section: 'short-app',
    step: 'short-app-started',
  });

  const buildRequestBody = useCallback(
    (chain: Chain[]) => {
      const newBody = chain.reduce((acc, curr) => {
        const fullQuestion = shortAppForm[curr.id] as FlowNode;
        if (fullQuestion.type === 'options') {
          if (fullQuestion.fieldName && body[fullQuestion.fieldName as keyof ShortAppCompleteDto] != null) {
            return { ...acc, [fullQuestion.fieldName as string]: body[fullQuestion.fieldName as keyof ShortAppCompleteDto] };
          }
          return acc;
        }
        fullQuestion?.inputs?.forEach((input) => {
          const { type, fieldName } = input;
          switch (type) {
            case 'downpayment': {
              acc = {
                ...acc,
                originalMortgageAmount: body.originalMortgageAmount,
                totalDownPayment: body.totalDownPayment,
                purchasePrice: body.purchasePrice,
              };
              break;
            }
            case 'loanAmount': {
              acc = {
                ...acc,
                estimatedValue: body.estimatedValue,
                originalMortgageAmount: body.originalMortgageAmount,
                remainingMortgageAmount: body.remainingMortgageAmount,
                additionalFundsAmount: body.additionalFundsAmount,
              };
              break;
            }
            case 'autocomplete': {
              acc = {
                ...acc,
                interestedLocation: body.interestedLocation,
                address: {
                  province: body.address?.province,
                  city: body.address?.city,
                  postalCode: body.address?.postalCode,
                },
              };
              break;
            }
            default: {
              if (body[fieldName as keyof ShortAppCompleteDto] != null) {
                acc = { ...acc, [fieldName as string]: body[fieldName as keyof ShortAppCompleteDto] };
              }
            }
          }
        });
        return acc;
      }, {});
      replaceBody(newBody);
    },
    [body, replaceBody, shortAppForm]
  );

  const answerQuestion = useCallback(
    (question: FlowNode, answer: Record<string, any>): void => {
      trackEvent(`${startCase(question?.secondaryFieldName ?? question?.fieldName ?? question.id)} Short App Answered`, {
        section: 'short-app',
        step: question?.secondaryFieldName ?? question?.fieldName ?? question.id,
        value: answer?.target?.value ?? answer,
      });

      const nextQId =
        question?.type === 'options'
          ? question.options?.find((option: OptionStruct) => option.value === answer.target?.value)?.next
          : question.next;
      const qIdx = flow.chain.findIndex((quest: Chain) => quest.id === question.id);

      if (nextQId === FlowState.NEXTSECTION && onSubmit) {
        onSubmit(body);
        return;
      }

      updateFlow({
        nextQId,
        currActiveQIdx: qIdx,
        apiState: API_STATUS.LOADED,
      });
      if (!question?.fieldName && question.type !== 'form') return;

      switch (question.type) {
        case 'options':
          updateBody({ [question.fieldName as string]: answer.target?.value });
          break;
        case 'customized':
        case 'form': {
          const payload = question?.buildPayload?.(answer) || answer;
          updateBody(payload);
          break;
        }
      }
    },
    [body, flow.chain, onSubmit, trackEvent, updateBody, updateFlow]
  );

  const backClick = useCallback(
    () =>
      updateFlow({
        prevActiveQIdx: flow.prevActiveQIdx - 1,
        nextQId: flow?.chain?.[flow?.currActiveQIdx]?.id,
        currActiveQIdx: flow.prevActiveQIdx,
      }),
    [flow?.chain, flow?.currActiveQIdx, flow.prevActiveQIdx, updateFlow]
  );

  useEffect(() => {
    buildRequestBody(flow.chain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow.chain]);

  useEffect(() => {
    dispatch({ type: ReducerActionType.SET_STEPS, steps });
  }, [dispatch, steps]);

  return (
    <>
      {!!currentQuestion && (
        <Question
          data={body}
          question={currentQuestion as FlowNode}
          onAnswer={answerQuestion}
          onBack={flow.prevActiveQIdx > -1 ? backClick : undefined}
        />
      )}
    </>
  );
};

export default ShortAppFlow;
