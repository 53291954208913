import styled from 'styled-components';

const StyledTooltipContent = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.h2`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.text.primary};
  `,
  Content: styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.text.secondary};
  `,
};

export default StyledTooltipContent;
